<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="flex justify-content-between mb-4 table-label-search">
          <h3>Disabled Companies</h3>
          <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="search" placeholder="Keyword Search" @input="onSearch" />
          </span>
        </div>

        <EditCompany/>

        <DataTable
          :value="getCompanies" :lazy="true" :paginator="true" :first="getPagination?.from-1"
          :rows="getPagination?.per_page ?? 25"  ref="dt" dataKey="id"
          :totalRecords="getPagination.total" :loading="loading" @page="onPage($event)"
          @sort="onSort($event)" :sortField="sort" :sortOrder="order"
          responsiveLayout="scroll" :rowsPerPageOptions="[10,25,50]"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <template #empty>
            Not found.
          </template>

          <!-- Company Name -->
          <Column field="name" header="Company Name" :sortable="true"/>

          <!-- Admin Full Name -->
          <Column field="admin_name" header="Admin Full Name" :sortable="true">
            <template #body="slotProps">
              {{slotProps.data.first_name + ' ' + slotProps.data.last_name}}
            </template>
          </Column>

          <!-- Admin Email -->
          <Column field="email" header="Admin Email" :sortable="true" />

          <!-- Balance -->
          <Column field="balance" header="Balance" :sortable="true" />

          <!-- Frozen Balance -->
          <Column field="frozen_balance" header="Frozen Balance" :sortable="true" />

          <!-- Notes -->
          <Column field="notes" header="Notes" :sortable="true" />

          <!-- Actions -->
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                v-tooltip="'Edit'"
                icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2"
                @click="edit(slotProps.data.id)"
              />
              <Button
                v-tooltip="'Approve'"
                icon="pi pi-check" class="p-button-rounded p-button-success mr-2"
                @click="approved(slotProps.data.id)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import EditCompany from "@pages/app/Companies/EditCompany";
import store from "@store";
import dataTable from "@/mixins/dataTable";
import companyResource from "@http/CompanyResource";
import toastParamBuilder from "@builders/ToastParamBuilder";

export default {
  mixins: [dataTable],
  components: {EditCompany},

  methods: {
    edit(id) {
      this.loading = true;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          company: id,
        }
      });
    },
    approved(id) {
      companyResource.approve(id)
        .then(({data}) => {
          this.$toast.add(toastParamBuilder.success(data));
          this.$router.push({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              _r: Date.now(),
            }
          });
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },
  },

  computed: {
    ...mapGetters({
      getCompanies: "company/getCompanies",
      getPagination: "company/getPagination"
    }),
  },

  beforeRouteEnter(to, from, next) {
    store.dispatch('company/declinedRequests', to.query)
      .finally(() => {
        next((vm) => {
          vm.loading = false;
        });
      });
  },

  async beforeRouteUpdate(to) {
    await store.dispatch('company/declinedRequests', to.query)
      .finally(() => {
        this.loading = false;
      });
  },
}
</script>

<style scoped lang="scss">
@import '../../../assets/demo/badges.scss';
::v-deep(.p-paginator) {
 .p-paginator-current {
   margin-left: auto;
 }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
